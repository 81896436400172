 @import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
 @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800");
 @import url("https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800");
 @import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap");

 :root {
   --primary-bg-color: #0068ff;
   --primary-transparentcolor: #e4e9ff;
   --primary-bg-color: #0068ff;
   --indigo: #4b0082;
   --purple: #6f42c1;
   --pink: #f1388b;
   --orange: #fd7e14;
   --yellow: #ffc107;
   --green: #28a745;
   --teal: #20c997;
   --cyan: #17a2b8;
   --white: #fff;
   --gray: #6c757d;
   --gray-dark: #343a40;
   --primary: #007bff;
   --secondary: #6c757d;
   --success: #28a745;
   --info: #17a2b8;
   --warning: #ffc107;
   --danger: #dc3545;
   --light: #f8f9fa;
   --dark: #343a40;
   --breakpoint-xs: 0;
   --breakpoint-sm: 576px;
   --breakpoint-md: 768px;
   --breakpoint-lg: 992px;
   --breakpoint-xl: 1200px;
   --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
 }

 * {
   box-sizing: border-box;
 }

 *::before,
 *::after {
   box-sizing: border-box;
 }

 html {
   font-family: sans-serif;
   line-height: 1.15;
   -webkit-text-size-adjust: 100%;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 }

 article,
 aside,
 figcaption,
 figure,
 footer,
 header,
 hgroup,
 main,
 nav,
 section {
   display: block;
 }

 body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
   font-size: 0.875rem;
   font-weight: 400;
   line-height: 1.5;
   text-align: left;
   background-color: #ffffff;
   font-family: "Roboto", sans-serif !important;
   background: #f0f0ff;
   color: #1d212f;
   direction: ltr;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   -webkit-tap-highlight-color: transparent;
   -webkit-text-size-adjust: none;
   -ms-touch-action: manipulation;
   touch-action: manipulation;
   -webkit-font-feature-settings: "liga" 0;
   font-feature-settings: "liga" 0;
   overflow-y: scroll;
   width: 100%;
   position: relative;
 }

 [tabindex="-1"]:focus {
   outline: 0 !important;
 }

 hr {
   box-sizing: content-box;
   height: 0;
   overflow: visible;
   margin-top: 1rem;
   margin-bottom: 1rem;
   border: 0;
   border-top: 1px solid #e8e8f7;
 }

 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   margin-top: 0;
   margin-bottom: 0.5rem;
 }

 p {
   margin-top: 0;
   margin-bottom: 1rem;
 }

 body {
   font-size: 0.875rem;
   font-weight: 400;
   line-height: 1.5;
   text-align: left;
   font-family: "Roboto", sans-serif !important;
   color: #1d212f;
   direction: ltr;
   -webkit-text-size-adjust: none;
   font-feature-settings: "liga";
   background: #eaedf7 !important;

 }

 .page-header {
   display: flex;
   -ms-flex-align: center;
   align-items: center;
   margin: 1.5rem 0 1.5rem;
   -ms-flex-wrap: wrap;
   justify-content: space-between;
   padding: 0;
   position: relative;
   min-height: 50px;
   margin: 1.8rem 0 0.75rem 0;
   border-radius: 7px;
 }

 a {
   color: #424e79;
 }

 .accordion .card-header {
   padding: 0px !important;
 }

 .accordion .card-header a {
   display: block;
   padding: 14px 20px;
   position: relative;
   font-weight: 500;
   font-size: 14px;
   background-color: #e1e1f9;
 }


 .btn.btn-white:hover svg {
   fill: #fff;
 }

 .fontAwesome-size-medium {
   max-width: 15px;
   margin-right: 4px;
   margin-top: -2px;
 }

 .main-content-title {
   color: #170c6b;
   font-weight: 500;
   font-size: 32px;
   text-indent: -1px;
   line-height: 1;
   position: relative;
 }

 .tx-24 {
   font-size: 24px;
 }

 .mg-b-5 {
   margin-bottom: 5px;
 }

 .page-header .breadcrumb {
   background: none;
   padding-left: 0 !important;
   padding: 0;
   margin-bottom: 0;
 }

 .breadcrumb {
   display: flex;
   flex-wrap: wrap;
   padding: 0.75rem 1rem;
   margin-bottom: 1rem;
   list-style: none;
   border-radius: 0px;
 }

 .card {
   border-radius: 11px;
   position: relative;
   display: flex;
   flex-direction: column;
   min-width: 0;
   word-wrap: break-word;
   background-color: #fff;
   background-clip: border-box;
 }

 .btn,
 .sp-container button {
   line-height: 1.538;
   padding: 7px 20px;
   border-radius: 4px;
   transition: none;
   min-height: 38px;
   display: inline-block;
   font-weight: 400;
   color: #8f9cc0;
   text-align: center;
   vertical-align: middle;
   user-select: none;
   background-color: transparent;
   border: 1px solid transparent;
   padding: 0.375rem 0.75rem;
   font-size: 0.875rem;
   line-height: 1.5;
   border-radius: 3px;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 }

 .btn-white:hover {
   background-color: #424e79;
   border-color: #424e79;
   background-image: none;
   border-color: #fff;
   color: #fff;
 }

 .btn-white {
   background-color: #fff;
   background-image: none;
   border-color: #fff;
   color: #424e79;
 }

 .card-item-icon.card-icon svg {
   max-width: 24px;
 }

 .fontAwesome-size {
   max-width: 20px;
 }

 .fontAwesome-size-small {
   max-width: 10px;
 }

 .text-success svg {
   fill: #28a745 !important;
 }

 .text-danger svg {
   fill: #dc3545 !important;
 }

 .card-item .card-item-body .card-item-stat small {
   font-size: 85%;
   font-weight: 300;
   text-transform: capitalize;
   color: #8d95b1;
 }

 .font-weight-bold {
   font-weight: 700 !important;
 }

 .custom-card {
   margin-bottom: 20px;
   border: 0;
   box-shadow: -7.829px 11.607px 20px 0px #e4e3f5;
 }

 .main-content .container,
 .main-content .container-fluid {
   padding-left: 25px;
   padding-right: 25px;
 }

 .main-header>.container,
 .main-header>.container-fluid {
   padding-left: 25px;
   padding-right: 15px;
 }

 .card-item .card-item-icon.card-icon {
   background: #4d4cb21a;
 }

 .card-item .card-item-icon {
   width: 45px;
   height: 45px;
   position: absolute;
   right: 20px;
   bottom: 21px;
   top: auto;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .main-content-label,
 .card-table-two .card-title,
 .card-dashboard-eight .card-title {
   color: #1d212f;
   font-weight: 800;
   text-transform: uppercase;
   line-height: 1;
   margin-bottom: 10px;
   letter-spacing: .2px;
 }

 @media only screen and (min-width:992px) {
   .main-header-left {
     display: none;
   }

   .main-header-center {
     display: none;
   }

   .main-header-right {
     width: 100%;
   }
 }

 @media only screen and (max-width:992px) {
   .main-header-left {
     display: flex;
   }

   .main-header-center {
     display: flex;
   }

   .main-header-right {
     display: none;
   }

   .mobile-logo {
     max-width: 100px;
     position: relative;
     top: -4px;
   }
 }

 .row {
   min-width: 100%;
 }

 .position-fixed-footer {
   position: fixed;
   left: 0px;
   bottom: 0px;
   right: 0px;
 }

 .text-with-logo {
   float: left;
   width: 100%;
 }

 .side-header {
   position: fixed !important;
 }

 .blackToast .Toastify__toast-body {
   line-height: 24px;
 }

 .blackToast {
   background-color: #000;
   line-height: 1 !important;
 }

 .blackToast .Toastify__progress-bar {
   background-color: #e35a88;
 }

 .message-watch {
   background: #eefaec;
   padding: 10px;
   width: 100%;
   border-left: 4px solid #008000;
 }

 .text-with-logo * {
   display: inline-block;
   float: none !important;
   vertical-align: middle;
   margin-bottom: 0px !important;
   padding-right: 5px;
 }

 .header-right ul {
   margin-bottom: 0px;
   text-align: right;
   display: block;
 }

 .header-right ul li {
   list-style: none;
   text-align: right;
   display: inline-block;
   line-height: 50px;
 }

 .main-header.side-header {
   line-height: 50px;
 }

 .header-right ul li a {
   padding: 0px 15px !important;
 }

 .main-body .main-header {
   height: 60px;
 }

 .main-header.side-header,
 .main-header.side-header ul li {
   line-height: 50px;
   font-size: 13px;
   color: #4a4d51;
 }

 .main-header.side-header ul li a {
   color: #4a4d51;
 }

 .main-header.side-header ul li a i {
   margin-right: 5px;
 }

 .main-header.side-header ul li a .main-header.side-header ul li a:hover {
   color: #e35a88
 }

 .main-sidebar-body li.active .sidemenu-icon {
   font-size: 18px;
   line-height: 0;
   margin-right: 10px;
   width: 35px;
   height: 35px;
   line-height: 35px;
   text-align: center;
   border-radius: 50%;
   -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
   color: #ffffff;
 }

 .main-sidebar-body li.active .sidemenu-icon {
   background: #e35a88;
 }

 .main-sidebar-body li.active a .sidemenu-label {
   color: #e35a88;
 }

 .btn.btn-white.btn-icon-text i {
   padding-right: 5px;
 }

 .card-item-stat-abs b {
   font-weight: bold;
   display: block;
   line-height: 12px;
 }

 .card-item-stat-abs {
   position: absolute;
   bottom: 15px;
 }

 .card-item-stat.card-item-stat-rel {
   position: relative;
   padding-bottom: 40px;
 }

 .heat-icon img {
   max-width: 50px;
   margin-bottom: 10px;
   padding: 10px;
   background-color: #f2f2f2;
   border-radius: 50%;
   opacity: .8;
 }

 .card.custom-card {
   min-height: 160px;
 }

 .card-item-stat.card-item-stat-table td:first-child {
   font-weight: 600;
 }

 .col-md-8.stats-area-left {
   padding-right: 0px;
 }

 .card-item-stat h4 {
   font-size: 14px;
 }

 .card-item-stat.card-item-stat-table {
   width: 100%;
   padding-bottom: 40px;
 }

 .card-item-stat-table-less .table {
   margin-bottom: 0px;
 }

 .custom-card .card-body {
   padding: 15px;
 }

 .card-item-stat.card-item-stat-table td {
   font-size: 12px;
   padding: 4px 0px;
   color: #8d95b1;
   border-bottom: 1px solid #f0f0ff;
   text-transform: capitalize;
 }

 .card-item-stat.card-item-stat-table tr:last-child td {
   border-bottom: 0px;
 }

 .card-item-stat.card-item-stat-table.card-item-stat-table-less {
   padding-bottom: 0px;
 }

 .accordion>.card:last-of-type .collapse.show .card-body {
   border-radius: 0px 0px 20px 20px !important;
   border-bottom: 1px solid #e8e8f7;
 }

 .btn-primary {
   color: #fff;
   background-color: #e35a88;
   border-color: #e35a88;
 }

 .btn-primary:hover {
   color: #fff;
   background-color: #e35a88;
   border-color: #e35a88;
 }

 #accordion .btn {
   padding: 10px 20px;
   line-height: 1;
   border-radius: 4px;
   border-radius: 4px;
   border: 0px;
 }

 #accordion .btn.cancel-btn {
   color: #fff;
   background-color: #656565;
 }

 .accordion>.card>.card-header a {
   cursor: pointer;
   display: block;
 }

 .accordion .card-header a.collapsed:hover,
 .accordion .card-header a.collapsed:focus {}

 .accordion .card-header a {
   display: block;
   padding: 14px 20px;
   position: relative;
   font-weight: 500;
   font-size: 14px;
   background-color: #e1e1f9 !important;
   color: #3c4858 !important;
 }

 .accordion .card-header a.collapsed {

   background-color: #e1e1f9 !important;

   color: #8f9cc0 !important;
   background-color: #f5f5fd !important;

 }

 #accordion .btn.cancel-btn {
   margin-right: 10px;
 }

 .accordion>.card>.card-header a svg {
   max-width: 13px;
   margin-right: 5px;
   fill: #3c4858 !important;
 }

 .accordion>.card>.card-header a svg.fill-inner * {
   fill: #3c4858 !important;
 }

 .accordion>.card>.card-header a.collapsed svg,
 .accordion>.card>.card-header a.collapsed svg.fill-inner * {
   fill: #8f9cc0 !important;
 }

 .accordion>.card>.card-header a.collapsed svg {
   fill: #8f9cc0 !important
 }

 .ant-picker-cell .ant-picker-cell-inner,
 .ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
   font-size: 12px;
   color: #4a4d51;
 }

 .ant-picker {
   height: calc(1.5em + .75rem + 2px);
   padding: .375rem .75rem;
 }

 .ant-space.ant-space-vertical {
   gap: 0px !important;
 }

 #gauge-chart4 .text-group {
   display: none;
 }

 iframe {
   z-index: -9 !important;
 }

 .pos-abs-right {
   position: absolute;
   right: 15px;
   top: 15px;
   font-size: 16px;
   line-height: 30px;
 }

 .breadcrumb li a {
   color: #7c858d;
 }

 .br-btm-field {
   padding-bottom: 10px;
   margin-bottom: 10px;
   border-bottom: 1px solid #e1e1f9;
 }

 .accordion .mt-3.text-right {
   background-color: #fbfbfb;
   padding: 10px;
   border: 1px solid #ddd;
 }

 .accordion .mt-3.text-right {
   background-color: #fbfbfb;
   padding: 10px;
   border-top: 1px solid #ddd;
   margin-left: -20px;
   margin-bottom: -20px;
   margin-right: -20px;
 }

 .subTabSafeAir {
   border-bottom: 1px solid #ddd;
   margin-bottom: 15px;
 }

 .subTabSafeAir .subtab {
   padding: 10px 20px;
   display: inline-block;
   border-bottom: 1px solid transparent;
   cursor: pointer;
 }

 .float-end {
   float: right;
 }

 .OnBoardAreaa {
   width: 100%;
   background: #fff;
   padding: 20px;
 }

 .btn-primary svg {
   max-width: 12px;
   position: relative;
   top: -1px;
   fill: #fff;
   margin-right: 4px;
 }

 .ag-theme-alpine .ag-header {
   background: #f5f5fd;

 }

 .element-icon .btn.btn-delete.btn-primary {
   max-width: 30px;
 }

 .btn.btn-delete.btn-primary img {
   max-width: 20px;
 }

 .subTabSafeAir .subtab:first-child {
   padding-left: 0px;
 }

 .subTabSafeAir .subtab.active {
   color: #e35a88;
   border-bottom: 1px solid #e35a88;
   margin-bottom: -1px;
 }

 .ant-select .ant-select-selector {
   display: block;
   width: 100%;
   height: calc(1.5em + .75rem + 2px) !important;
   padding: .375rem .75rem !important;
   font-size: 1rem !important;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #ced4da;
   border-radius: .25rem;
   transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
 }

 .ant-select-selection-placeholder {
   line-height: 25px !important;
   font-size: 1rem !important;
   color: #495057;
   opacity: .7;
 }

 .ant-radio-inner::after {
   color: #e35a88 !important;
 }

 .ant-radio-inner::after {
   background-color: #e35a88 !important;
 }

 .ant-radio-wrapper:hover .ant-radio,
 .ant-radio:hover .ant-radio-inner,
 .ant-radio-input:focus+.ant-radio-inner,
 .ant-radio-checked .ant-radio-inner {
   border-color: #e35a88 !important;
 }

 .ant-radio-checked .ant-radio-inner {
   border-color: #e35a88;
 }

 .ant-checkbox-checked .ant-checkbox-inner {
   background-color: #e35a88 !important;
   border-color: #e35a88 !important;
 }

 .ant-checkbox-checked::after {
   border: 1px solid #e35a88 !important;
 }

 .ant-select {
   width: 100%;
 }

 .headerTabMac {
   width: 100%;
 }

 .headerTabMac .tab-mac {
   font-size: 12px;
   padding: 5px 15px;
   color: #8d95b1;
   font-weight: bold;

   display: inline-block;
   border-bottom: 1px solid transparent;
   cursor: pointer;
 }

 .headerTabMac .tab-mac:first-child {
   padding-left: 0px;
 }

 .headerTabMac .tab-mac.active {
   color: #e35a88;
   border-color: #e35a88;
 }

 .element-icon .btn.btn-delete.btn-primary {
   max-width: 30px;
   width: 30px;
   height: 15px !important;
   display: inline-block;
   min-height: 30px;
   padding: 0px !important;
 }

 .logoutModal .row {
   margin: 0px;
 }

 .modalAddDevice .logoutTitle {
   font-size: 20px;
   font-weight: 500;
 }

 .modalAddDevice .btn-close.btn-tiny {
   position: absolute;

   right: 20px;
   top: 20px;
   width: 25px;
   height: 25px;
   background-color: #e35a88;
   text-align: center;
   color: #fff;
   cursor: pointer;
 }

 .modalAddDevice .br-btm-field {
   padding-bottom: 0px;
   margin-bottom: 20px;
 }

 .ReactModal__Content.ReactModal__Content--after-open.modalAddDevice {
   height: 320px !important;
   background-color: #f9fafa !important;
 }

 .modalAddDevice .btnText.okBtn.btn.btn-primary {
   width: 100%;
 }

 legend .main-content-label.mb-1 {
   font-size: 12px;
 }

 .br-top-field {
   padding-top: 15px;
   margin-top: 15px;
   border-top: 1px solid #ddd !important;
 }

 .ssid-scanner {
   position: relative;
 }

 .ssid-scanner .scan-submit {
   position: absolute;
   right: 1px;
   width: 35px;
   padding: 6px;
   background: #fcfcfc;
   top: 1px;
   border-radius: 4px;
   cursor: pointer;
   border: 1px solid #f4f4f4;
 }

 .card.custom-card.custom-card-stats-size-4 {
   min-height: 200px;
 }

 .card.custom-card.custom-card-stats.custom-card-stats-size-auto {
   min-height: 1px;
 }

 .custom-card-stats-size-auto table tr:first-child td::before {
   content: '';
   width: 6px;
   height: 6px;
   background-color: #8d95b1;
   position: relative;
   display: inline-block;
   border-radius: 50%;
   margin-right: 5px;
   top: -1px;
 }

 .card.custom-card.custom-card-stats.custom-card-stats-size-3 {
   min-height: 390px;
 }

 .custom-card-stats-size-auto table tr:first-child td {
   text-transform: uppercase;
   background: #e7e8f6;
 }

 .custom-card-stats-size-auto .card-item-stat-table-less .table td {
   padding: 5px 20px;
   border-right: 1px solid #ddd;
 }

 .custom-card-stats-size-auto .card-item-stat-table-less .table {
   border: 1px solid #ddd;
   border-radius: 12px !important;
 }

 .main-header.side-header ul li svg {
   max-width: 10px;
   margin-right: 5px;
   fill: #fff;
 }

 .main-header.side-header ul li .btn-shape {
   background: #e35a88;
   padding: 7px 20px;
   border-radius: 5px;
   color: #fff;
   width: 100px;
   display: inline-block;
   line-height: 1;
 }

 .rebootNote strong {
   color: #e35a88;
   font-weight: normal;
 }

 .help-form.help-form-error {
   color: #ab3131;
 }

 .main-header.side-header ul li .btn-shape:hover {
   background-color: #ed5e8e;
 }

 .main-header.side-header ul li a {
   display: inline-block;
 }

 .timercolor {
   font-weight: bold;
 }

 .ant-select-dropdown-empty {
   display: none !important;
 }

 .has-error.form-control {
   border: 1px solid red;
 }

 .loadingMain {
   display: none;
   text-align: center;
 }

 .loading .loadingMain {
   display: block;
 }

 .loading fieldset {
   display: none;
 }

 .has-error {
   border: 1px solid red;
 }

 .ant-switch-checked {
   background-color: #e35a88;
 }

 .btn[disabled],
 .btn-primary.disabled {
   background-color: #e35a88;
   opacity: .5;
 }

 .ant-switch-checked {
   background-color: #e35a88 !important;
 }

 .btn-primary.focus,
 .btn-primary:focus,
 .btn-primary:not(:disabled):not(.disabled):active {
   color: #fff;
   background-color: #e35a88;
   border-color: #e35a88;
   box-shadow: 0 0 0 .2rem #e35a8830 !important;
 }

 .help-form {
   color: #a29999;
 }

 .loaderspinner {
   border: 3px solid #f3f3f3;
   border-radius: 50%;
   border-top: 3px solid #a8a7b3;
   width: 25px;
   height: 25px;
   animation: spin 2s linear infinite;
   display: inline-block;
 }

 .sidemenu-logo.log-safeair img {
   max-width: 200px;
 }

 .sidemenu-logo.log-safeair {
   padding: 18px 15px;
 }

 .btn.invite-btn.btn-primary.btn-icon-text:hover {
   background-color: #656565;
 }

 .btn.cancel-btn.btn-white:hover {
   background-color: #e35a88;
 }

 /* Safari */
 @-webkit-keyframes spin {
   0% {
     -webkit-transform: rotate(0deg);
   }

   100% {
     -webkit-transform: rotate(360deg);
   }
 }

 @keyframes spin {
   0% {
     transform: rotate(0deg);
   }

   100% {
     transform: rotate(360deg);
   }
 }

 .toast-class-loading .Toastify__close-button.Toastify__close-button--default {
   display: none !important;
 }

 .Toastify__toast-body,
 .Toastify__toast-body * {
   line-height: 30px;
   display: inline-block;
 }

 .toast-class-loading .Toastify__toast-body span.loaderspinner {
   position: absolute;
   left: 10px;
 }

 .toast-class-loading .Toastify__toast.Toastify__toast--default {
   background: #fff;
   color: #aaa;
   padding-top: 0px;
   padding-bottom: 0px;
   height: 50px !important;
   overflow: hidden;
   min-height: 50px;
 }

 .toast-class-loading .Toastify__toast-body {
   padding-left: 40px;
 }

 .toast-class-loading .Toastify__toast-body,
 .toast-class-loading .Toastify__toast-body * {
   line-height: 25px;
   display: inline-block;
 }

 .toast-class-loading .Toastify__close-button {
   display: none !important;
 }

 .toast-class-loading .Toastify__progress-bar {
   display: none !important;
 }

 .ant-radio-group.has-error .ant-radio-inner {
   border-color: red;
 }

 .ant-radio-group.has-error {
   border: 0px;
 }

 .form-field.formfielSubmit .btn.btn-submit {
   margin-top: 25px;
   background: #ef5e8c;
   color: #fff;
   width: 100%;
 }

 .ag-cell-value {
   text-transform: capitalize;
 }