@import url('https://fonts.googleapis.com/css2?family=Neonderthaw&family=Sen:wght@400;700;800&display=swap');

*,
body {
  font-family: 'Sen', sans-serif;
}

.onBoardScanArea .onBoardScanInner .form-field {
  width: 100%;
}

.OnBoardAreaa {
  width: 100%;
  background: #fff;
  padding: 20px;
  padding-top: 60px;
  margin-top: -60px;
}


.onBoardAreaInner {
  float: none;
  margin: 20px auto;
  width: 1000px;
}

.errormessage {
  background: #f7f8f9;
  padding: 2px 20px;
  border-left: 4px solid red;
  border-radius: 4px;
  margin-top: 6px;
  position: absolute;
  width: 100%
}

.sucessMessage {
  background: #f7f8f9;
  padding: 2px 20px;
  border-left: 4px solid green;
  border-radius: 4px;
  margin-top: 6px;
  position: absolute;
  width: 100%
}

.fieldBottom {
  margin: 12px 0px;
}

.fieldBottom .fieldList {
  margin-bottom: 2px;
  font-size: 12px;
}

.contentArea {
  margin-top: 30px;
  background: #fff;
  padding: 40px;
  z-index: 99;
  position: relative;
  border-radius: 8px;
}

.onBoardLeft {
  float: left;
}

.onBoardright {
  float: right;
}

.btn.btn-pink {
  min-width: 100px;
  background: #ef5e8c;
  color: #fff;
}

.breadcrumb-item.active strong {
  color: #e7457b;
}

.ant-modal {
  border-radius: 10px !important;
  overflow: hidden;
}

.ant-btn.ant-btn-primary:hover {
  background: #cc2359;
  border-color: #ef5e8c;
  min-width: 100px;
}

.ant-btn.ant-btn-default:hover {
  color: #e7457b;
  border-color: #ef5e8c;

}

.tableArea {
  width: 100%;
}

.textCapitalize.textCap.ag-cell-value {
  text-transform: capitalize;
}

.topArea {
  float: left;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.ag-theme-alpine.if.cell-size-40 {
  overflow: hidden;
}



.tableContentArea.row .errorMessage {
  margin-top: 15px;
}


.rightDivHeader div {
  display: inline-block;
}

.rightDivHeader {
  min-width: 200px;
}

.rightDivHeader .hourStyle {
  padding: 9px 20px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
}

.rightDivHeader .btnLogout .btn {
  margin-left: 10px;
  font-size: 13px;
  border-radius: 4px;
  color: #fff;
  width: 40px;
  border: 0px;
  background: #fff;
  padding: 0px;
}

.rightDivHeader .btnLogout .btn img {
  max-width: 15px;
}

.ant-btn.ant-btn-primary {
  background: #ef5e8c;
  border-color: #ef5e8c;
  min-width: 100px;
}

.logoArea img {
  max-width: 100%;
  height: auto;
  width: auto;
}

.datepickerStyle .react-datepicker-wrapper,
.datepickerStyle .react-datepicker-wrapper input {
  max-width: 100px !important;
}

label .datepickerStyle .react-datepicker-wrapper input {
  border: 1px solid #ddd;
  padding: 5px;
  margin: 0px 10px;
}

.datepickerStyle {
  margin-right: 30px;
}

.datepickerStyle .labelDiv,
.datepickerStyle input {
  display: inline-block;
}

.datepickerStyle .react-datepicker-wrapper input {
  margin: 0px 10px;
  padding: 5px 20px;
  font-size: 12px;
  border: 1px solid #ddd;
}

.datepickerStyle {
  display: inline-block;
}

.topStatsArea {
  margin-bottom: 20px;
}

.topStatsArea .bgWhite {
  padding: 10px 60px 30px 60px;
  background: #fff;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px #e2ecf980;
  overflow: auto;
}

.biggerCountArea {
  font-size: 72px;
  text-align: center;
  color: #170c6b;
}

.smallerTitleArea {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: #929699;
}

.statsLeftRight {
  font-size: 13px;
  color: #414c51;
  margin-top: 20px;
}

.smallerSubTitleArea {
  text-align: center;
}

.onBoardScanArea .onBoardScanInner {
  width: 100%;
  float: none;
  margin: 0 auto;

  border-radius: 8px;
  z-index: 99;
  position: relative;
  overflow: hidden;
}

.topAreaScan.row {
  padding: 20px;
  margin: 0px;
  background: #263053;
}

.ScanFieldFilter.row {
  margin: 0px;
  padding: 20px;
  background: #fff;
  margin-top: 15px;
}

.topAreaScan .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #fcfdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #d9d9d940;
}

.topAreaScan.row .fieldBottom .fieldList {
  font-size: 14px;
  margin-bottom: 7px;
}

.topAreaScan.row .col-lg-4:last-child .innerDiv {
  height: 120px;
}

.topAreaScan.row .innerDiv {
  padding: 20px;
  border: 2px solid #ddd;
  height: 100%;
  background: #e9ecf6;
  border-radius: 8px;
}

.form-field.formfielSubmit .btn.btn-submit {
  margin-top: 14px;
  background: #ef5e8c;
  color: #fff;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
}

.onBoardScanArea .onBoardScanInner .form-field {
  width: 100%;
}

.onBoardScanArea .onBoardScanInner .form-field p {
  width: 40px;
  float: left;
  margin-top: 0px !important;
  background: #fff;
  line-height: 35px;
  padding: 0px;
}

.topAreaScan.row .col-lg-6 label {
  color: #fff;
}

.onBoardScanArea .onBoardScanInner .form-field p svg {
  max-width: 16px;
}

.ant-select.ant-select-single.ant-select-show-arrow.ant-select-disabled {
  opacity: .6;
}

.onBoardScanArea .onBoardScanInner .form-field.form-field2 {
  float: none;
  margin: 0 auto;
  width: 500px;
}

.onBoardScanArea .onBoardScanInner .form-field.form-field2 input {
  width: 400px;
  float: left;
  background: #eaedf7;
}

.Toastify__toast-container--top-right {
  width: auto !important;
  max-width: 400px;
}

.onBoardScanArea .onBoardScanInner .form-field.form-field2 input[disabled] {
  background: #837c7c;
  border-color: #837c7c;
  transition: ease-in .3s;
}

.onBoardScanArea .onBoardScanInner .form-field.form-field2 input[disabled]::placeholder {
  opacity: 1;
  font-size: 13px;
  color: #c4bebe;
}

.noteMessage {
  display: inline-block;
  background: #263053;
  color: #fff;
  padding: 4px 10px;
  font-size: 12px;
}

.ant-modal.logoutModal .ant-modal-header {
  text-transform: uppercase;
  font-weight: bolder;
}

.ant-modal.logoutModal {
  height: 245px;
}

.ant-modal.logoutModal .ant-modal-footer {
  text-align: center;
  height: 70px;
}

.ant-modal.logoutModal .ant-modal-body {
  padding: 15px 21px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-modal.logoutModal .ant-modal-footer button {
  min-width: 47% !important;
  height: 45px;
  border-radius: 12px;
}

.ant-modal.logoutModal.logoutModalHieght2 {
  height: 450px;
  overflow: visible;
  border-radius: 10px !important;
  background: #ffebeb !important;
  padding: 4px;
}

.ant-modal.logoutModal.logoutModalHieght2 .warning-img {
  max-width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-bottom: 30px;
}

.ant-modal.logoutModal.logoutModalHieght2 .ant-modal-content {
  background-color: #ffebeb;
}

.ant-modal.logoutModal.logoutModalHieght2 .ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #ffebeb;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.ant-modal.logoutModal.logoutModalHieght2 .ant-modal-content {
  background-color: #ffebeb;
  box-shadow: unset !important;
}

.modalHeaderCustom {
  text-align: center;
}

.ant-modal.logoutModal.logoutModalHieght2 p strong {
  font-size: 16px;
}

.shiftShort {
  font-size: 24px;
  text-align: center;
  color: #c02020;
  margin-top: -20px;
}

.shiftShort small {
  display: block;
  font-size: 14px;
  line-height: 1;
  color: #000;
}

.smallTaglineResp {
  display: none;
}


.div100 {
  float: left;
  width: calc(100% + 120px);
  margin: 0px -60px;
  margin-top: 20px;
  margin-bottom: -30px;
}

.smallLanguage .ant-select-selector {
  padding: 0px 10px !important;
  min-height: 10px !important;
  height: 30px !important;
  border: 0px !important;
  border-radius: 4px !important;
  text-align: left;
  font-size: 13px !important;
}

.div100 .div50 {
  width: 50%;
  float: left;
  text-align: center;
  padding: 6px;
  font-size: 16px;
  background: #f7f7f7;
}

.div100 .div50:first-child {
  border-right: 1px solid #e0e0e0
}

.div100 .div50 span {
  display: block;
  font-weight: bold;
  font-size: 24px;
}

.div100 .div50:first-child {
  background: #f7f7f7;
}

.div100 .div50:first-child span {
  color: #047204;

}

.div100 .div50:last-child span {
  color: red;
}

/* ---- Responsive start ------ */
@media only screen and (max-width: 1400px) {}

/*Small Desktop */
@media only screen and (max-width: 1200px) {}


@media only screen and (max-width: 1023px) and (min-width:992px) {}


@media only screen and (max-width:992px) {}


@media only screen and (max-width:767px) {
  .topStatsArea .bgWhite {
    padding: 10px 0px 30px 0px;
  }

  .topStatsArea .div100 {
    width: 100%;
    margin: 20px 0px -10px 0px;
  }

  .div100 .div50 span {
    display: inline-block;
    font-size: 12px;
  }

  .biggerCountArea {
    font-size: 60px;
  }

  .smallerTitleArea {
    font-size: 16px;
  }

  .onBoardScanArea .onBoardScanInner .form-field.form-field2 {
    width: 100%;
  }

  .onBoardScanArea .onBoardScanInner .form-field.form-field2 input {
    width: 70%;
  }

  .onBoardScanArea .onBoardScanInner .form-field p {
    width: 12%;
  }

  .topAreaScan.row .col-12 {
    margin-bottom: 15px;
  }

  .topAreaScan.row {
    padding-bottom: 0px;
  }

  .hideResp {
    display: none;
  }

  .page-header div {
    padding: 0px;
  }

  .smallTaglineResp {
    display: block;
  }

  .topAreaScan.row div {
    padding-left: 0px;
    padding-right: 0px;
  }

  .main-content .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .topStatsArea .row .col-6:first-child {
    padding-right: 0px;
  }

  .page-header {
    margin: 10px 0px;
    padding: 10px;
    background-color: #fff;
  }

  .datepickerStyle {
    width: calc(50% - 10px);
    float: left;
    margin-right: 10px;
  }

  .datepickerStyle .react-datepicker-wrapper,
  .datepickerStyle .react-datepicker-wrapper input {
    width: 100%;
    margin: 0px;
  }

  .noteMessage {
    display: none;
  }

  .contentArea {
    margin-top: 20px;
    padding: 12px;
  }

  .datepickerStyle .labelDiv,
  .datepickerStyle input {
    display: block;
    float: left;
    width: 100%;
    text-align: left;
  }

  .form-control.formLeftSPace {
    max-width: 50% !important;
    margin-top: 28px;
    padding: 0px 10px !important;
    height: 32px;
    font-size: 12px;
  }

  .btn.btn-pink.btn-responsive {
    margin-top: 25px;
    height: 27px !important;
    line-height: 30px !important;
    min-height: 33px;
  }

  .topArea h5 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }

  .loginComponentMainDiv .loginFormWithLogoDiv {
    width: 90%;
  }

  .loginFormWithLogoDiv .col-lg-3 {
    display: none !important;
  }

  .ag-theme-alpine .ag-header-cell,
  .ag-theme-alpine .ag-header-group-cell,
  .ag-theme-alpine .ag-cell,
  .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .nameAlign {
    font-size: 10px;
    line-height: 13px;
  }

  .nameAlign br {
    display: none;
  }

  .nameAlign small {
    display: block;

  }
}

@media only screen and (max-width:650px) {
  .topAreaScan.row {
    padding: 10px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 18px !important;
  }

  .smallLanguage .ant-select-single .ant-select-selector .ant-select-selection-item,
  .smallLanguage .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 32px !important;
  }

  .onBoardScanArea .onBoardScanInner .form-field.form-field2 input {
    font-size: 10px;
  }

  .onBoardScanArea .onBoardScanInner .form-field p svg {
    max-width: 12px;
  }

  .btn,
  .sp-container button {
    min-height: 25px !important;
    line-height: 25px !important;
  }

  .form-field.formfielSubmit .btn.btn-submit {
    margin-top: 6px;
    padding: 4px;
    font-size: 12px;
    border-radius: 8px;
  }

  .ant-select .ant-select-selector {
    height: 30px !important;
    font-size: 12px !important
  }

  .main-content .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .div100 .div50 {
    padding: 3px;
    font-size: 10px;
  }

  .div100 .div50 span {
    display: inline-block;
    font-size: 10px;
  }

  .topStatsArea .div100 {
    width: 100%;
    margin: 5px 0px -10px 0px;
  }

  .page-header {

    margin: 10px 0px;
  }

  .topAreaScan.row .col-12 {
    margin-bottom: 7px;
  }

  .topAreaScan.row div.col-lg-3 {
    margin-bottom: 2px;
  }

  .topAreaScan.row .col-lg-4:last-child .innerDiv {
    height: auto;
  }

  .onBoardScanArea .onBoardScanInner .form-field p {
    width: 10%;
    margin-bottom: 6px;
  }

  .contentArea {
    margin-top: 10px;
  }

  .loginComponentMainDiv .loginFormWithLogoDiv {
    margin: 0px !important;
  }

  .biggerCountArea {
    font-size: 60px;
  }

  .topArea .col-lg-6.col-6 {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100%;
  }

  .datepickerStyle .react-datepicker-wrapper,
  .datepickerStyle .react-datepicker-wrapper input {
    max-width: 100% !important;
  }

  .topArea .col-lg-3.col-6 {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0px 15px !important;
  }

  .form-control.formLeftSPace {
    margin-top: 5px;
    max-width: 65% !important;
  }


  .btn.btn-pink.btn-responsive {
    margin-top: 5px;
    padding: 0px;
    width: 15% !important;
    min-width: 30%;
    font-size: 12px;
  }

  .topArea h5 {
    font-size: 13px !important;
  }

  .rightDivHeader .hourStyle {
    padding: 6px 4px;
    font-weight: 500;
  }

  .page-header div {
    padding: 0px;
    font-size: 8px;
  }

  .rightDivHeader .hourStyle {
    padding: 6px 4px;
    font-weight: 500;
    font-size: 10px;
  }

  .rightDivHeader {
    min-width: 100px;
  }

  .topStatsArea .row .col-6:last-child {
    padding-left: 5px !important;
  }

  .biggerCountArea {
    font-size: 20px;
    line-height: 10px;
  }

  .smallerTitleArea {
    font-size: 10px;
    margin-top: 8px;
    margin-bottom: 3px;
  }

  .topStatsArea .bgWhite {
    padding: 10px 0px 10px 0px;
  }

  .topStatsArea {
    margin-bottom: 10px;
  }

  .topAreaScan.row {
    padding-bottom: 0px !important;
  }

  .ag-theme-alpine .ag-header-cell,
  .ag-theme-alpine .ag-header-group-cell,
  .ag-theme-alpine .ag-cell,
  .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .nameAlign {
    font-size: 10px;
  }

  .topAreaScan.row .innerDiv {
    padding: 0px 10px;
  }

  .topAreaScan.row .fieldBottom .fieldList {
    font-size: 10px;
    margin-bottom: 3px;
  }

  .onBoardScanArea .onBoardScanInner .form-field {
    font-size: 10px;
  }

  .btn.btn-pink.btn-responsive {
    height: 32px !important;
  }
}


/* Dashboard Mobile */
.main-content.main-contentMobile .page-header.row {
  padding: 0px;
  background: transparent !important;
  margin-bottom: 1px !important;
}

.firstClickArea {
  position: absolute;
  left: 0;
  right: 0;
  top: -10px;
  bottom: 0;
  z-index: 999;
  background-color: rgb(255, 255, 255, .9);
  height: 100%;
  cursor: pointer;
}

.onboardInnerStart {
  position: absolute;
  /* height: 120px; */
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  /* margin: 20px 10px; */
}

.onboardInnerStart img {
  max-width: 130px;
  margin: 10px 0px;
}

.onboardInnerStart h2 {
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 0px;
}

.onboardInnerStart p {
  color: #343649;
}